import React from "react"
import PropTypes from "prop-types"

import Box from "../../components/box"
import Column from "../../components/column"
import Columns from "../../components/columns"
import Stack from "../../components/stack"
import Text from "../../components/text"
import Title from "../../components/title"

const DateFormat = ({ children }) => (
  <Box bg="black" px={2} py={1} borderRadius="md">
    <Text sans bold color="white">
      {children}
    </Text>
  </Box>
)

const LebenInOrten = ({ showTitle }) => {
  return (
    <Stack>
      {showTitle && <Title top="Hölderlins Leben" bottom="in Orten" />}
      <Stack space={[12, 24]}>
        <Stack space={[3, 6]}>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1770</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Geburt in Lauffen am Neckar</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1774</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Umzug nach Nürtingen</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1784</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Eintritt in die Klosterschule Denkendorf
              </Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1786</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Eintritt in die Klosterschule Maulbronn
              </Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1788</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Studium am Evangelischen Stift Tübingen
              </Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1793</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Hauslehrer in Waltershausen</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1794</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Begegnungen mit Schiller und Fichte in Jena
              </Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1796</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Hauslehrer in Frankfurt am Main</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1798</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Bad Homburg</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1800</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Rückkehr nach Nürtingen</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1801</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Hauslehrer im schweizerischen Hauptwil
              </Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1802</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Hauslehrer in Bordeaux</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1802</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Rückkehr nach Nürtingen</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1804</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>Hofbibliothekar am Homburger Hof</Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1806</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Behandlung im Universitätsklinikum Tübingen
              </Text>
            </Column>
          </Columns>
          <Columns alignY="center">
            <Column width="content">
              <DateFormat>1807</DateFormat>
            </Column>
            <Column>
              <Text size={[3, 3, 4]}>
                Aufnahme im Haus der Familie Zimmer, heute: Hölderlinturm
                Tübingen
              </Text>
            </Column>
          </Columns>
        </Stack>
        <Columns alignY="center">
          <Column width="content">
            <DateFormat>1843</DateFormat>
          </Column>
          <Column>
            <Text size={[3, 3, 4]}>Hölderlin stirbt in Tübingen</Text>
          </Column>
        </Columns>
      </Stack>
    </Stack>
  )
}

LebenInOrten.propTypes = {
  showTitle: PropTypes.bool,
}

LebenInOrten.defaultProps = {
  showTitle: true,
}

export default LebenInOrten
