import React from "react"

import Heading from "./heading"
import Stack from "./stack"
import Text from "./text"

const Title = (props) => {
  return (
    <Stack>
      <Heading as="h2" level={1} lineHeight="none">
        <Text as="div">
          {props.top}
          {` `}

          {props.middle && (
            <Text as="span" color="whisper">
              {props.middle}
              {` `}
            </Text>
          )}
        </Text>
        <Text color="primary">{props.bottom}</Text>
      </Heading>
    </Stack>
  )
}

export default Title
