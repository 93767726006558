import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import Clearfix from "../../../../../components/clearfix"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FloatContainer from "../../../../../components/float-container"
import Heading from "../../../../../components/heading"
import Image from "../../../../../components/image"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LebenInOrten from "../../../../../content/hoelderlins-orte/leben-in-orten"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query SuedfrankreichWandererQuery {
      rechnung: file(relativePath: { eq: "kurse/schuhrechnung.png" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/stimmungsbarometer" />
      }
    >
      <Seo title="Das Reisen zu Hölderlins Zeit" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Reisen zu Hölderlins Zeit
        </Heading>
        <Clearfix>
          <FloatContainer width="40%" align="right">
            <Image
              image={data.rechnung.childImageSharp.gatsbyImageData}
              alt="Ein altes Blatt Papier mit einer Liste von Ausgaben"
              caption="Eine Rechnung für Schuhe aus Hölderlins Pflegschaftsakte im Stadtarchiv Nürtingen"
            />
          </FloatContainer>
          <Paragraph mb={[6, 12]}>
            Hölderlin war ein sportlicher Wanderer, der Strecken von bis zu 50
            km am Tag zurücklegen konnte. Eine Reise, allein der Bildung,
            Inspiration und Neugier wegen, wie sie Goethe mit seiner gut 1
            ½-jährigen Italienreise zur gleichen Zeit unternahm, konnte sich
            Hölderlin nicht leisten. Doch hat Hölderlin auf seinen Fußmärschen
            von einer Hauslehrerstelle zur nächsten viele Eindrücke gesammelt.
            Das Gesehene und Erlebte hat er teils schon währenddessen in
            Stichworten festgehalten.
          </Paragraph>
          <Paragraph>
            Dass Hölderlin diese Strecken zu Fuß und nicht etwa mit der Kutsche
            zurückgelegt hat, war sicherlich nicht nur eine finanzielle
            Entscheidung. Hölderlin war ein großer Naturliebhaber, den es immer
            wieder ins Freie hinaustrieb und der daraus wichtige Impulse für
            sein Schreiben errang.
          </Paragraph>
        </Clearfix>
        <Paragraph>
          Seine Gedichte changieren oftmals zwischen einem Sehnen nach Weite auf
          der einen Seite und dem Heimweh auf der anderen Seite. Selbst in den
          letzten 36 Lebensjahren im Tübinger Turm, in denen er keine großen
          Wanderungen mehr unternahm, ging er oft stundenlang am Neckarufer vor
          dem Haus auf und ab.
        </Paragraph>
        <Heading level={3}>Hölderlins Leben in Orten</Heading>
        <LebenInOrten showTitle={false} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
